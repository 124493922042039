@import "../../../app-customs/config-scss/variables.scss";

.experience {
    display: flex;
    flex-direction: column;
    position: relative;
}

.experience-item {
    padding: 1em;
}

.experience-img-container {
    max-height: 20%;
    min-height: 250px;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-top: 1em;
}

.experience-img-container img {
    width: auto;
    height: 100%;
}

.experience .experience-item-wrapper {
    flex: auto; /* take remaining height */
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}


.exp-data {
    margin    : 0;
    padding   : 0;
    list-style: none;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    font-size : 1.2em;
    padding-left: 1em;

    &-item {
        display: flex;
        flex-direction: row;
        justify-content : left;
        align-items: center;
        padding: .4em;
        border-bottom: 1px solid #eee;
    }

    &-item:first-child {
        margin-top: .3em;
    }

    &-item:last-child {
        border-bottom: none;
    }

    &-date {
        flex        : 0 0 4em;
        margin-right: .3em;
        font-size   : .8em;
        text-align  : left;
        color       : $list-el-event;

        .start-date {
            font-weight: 700;
            font-size  : 1em;
        }

        .start-hour {
            font-weight: bold;
            font-size  : 1.2em;
        }

        .end-date,
        .end-hour {
            color: $endHourColor;
        }
    }

    &-description {
        display: flex;
        flex-direction: column;
        justify-content : center;
        flex-grow: 1;
        align-items: left;
    }

    &-title {
        color: $fontColor;
        font-size: 1em;

        &-sm {
            color: $fontColor;
            font-size: 0.8em;
        }
    }

    &-details {
        display: flex;
        flex-direction: row;
        justify-content : left;
        align-items: center;
        font-size : 0.8em;
    }

    &-location {
        // font-weight : bold;
    }

    &-info {
        // font-size : 0.8em;
        font-style: italic;
        opacity: 0.5;
        margin-left: 16px;
    }

    &-links {
        display: flex;
        flex-direction: row;
        justify-content : right;
        align-items: center;
        margin-left: 24px;
    }

    &-link {
        //width: 36px;
        //height: 36px;
        margin-right: 24px;
        cursor: pointer;
        color: $color3;

        &:before {
            color: $color3;
        }
    }
}
