@font-face {
  font-family: 'DINCondensed-Bold';
  font-weight: Bold;
  font-style: normal;
   src: url('../public/files/project/fonts/DINCondensedBold.woff') format('woff'),
    url('../public/files/project/fonts/DINCondensedBold.ttf') format('ttf');
}

@font-face {
  font-family: 'Diglu-Bold';
  font-weight: Bold;
  font-style: normal;
  src: url('../public/files/project/fonts/Diglu-Bold.woff') format('woff'),
    url('../public/files/project/fonts/Diglu-Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'Diglu-Bold';
  font-weight: normal;
  font-style: italic;
  src: url('../public/files/project/fonts/Diglu-BoldItalic.woff') format('woff'),
    url('../public/files/project/fonts/Diglu-BoldItalic.ttf') format('ttf');
}

@font-face {
  font-family: 'GopherText-Bold';
  font-weight: Bold;
  font-style: normal;
  src: url('../public/files/project/fonts/GopherText-Bold.woff') format('woff'),
    url('../public/files/project/fonts/GopherText-Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'GopherText-Regular';
  font-weight: normal;
  font-style: normal;
  src: url('../public/files/project/fonts/GopherText-Regular.woff') format('woff'),
    url('../public/files/project/fonts/GopherText-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'poppins';
  font-weight: normal;
  font-style: normal;
  src: url('../public/files/project/fonts/poppins.woff2') format('woff2'),
    url('../public/files/project/fonts/poppins.ttf') format('ttf');
}

@font-face {
  font-family: 'poppins_bold';
  font-weight: bold;
  font-style: normal;
  src: url('../public/files/project/fonts/poppins_bold.woff2') format('woff2'),
    url('../public/files/project/fonts/poppins_bold.ttf') format('ttf');
}

.title-font {
  font-family: 'GopherText-Regular', Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.content-font {
  font-family: 'Cabin-Regular', Arial, Helvetica, sans-serif;
}

.app-toolbar .content-font,
.home-tile {
  font-family: 'GopherText-Regular', Arial, Helvetica, sans-serif;
}

.icon-font {
  font-family: 'project';
}

input,
.generic-input,
::-webkit-input-placeholder {
  @extend .content-font;
}