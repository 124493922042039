$pageBackgroundColor: white;
$ficheBackgroundColor: white;


$color1: #1d1d1b;
$color1lighter: lighten($color1, 30%);
$color1evenMoreLighter: lighten($color1, 52%);
$color2: #17ccbe;
$color3: $color2;
$red: #FF0614;

$fontColor: #48484a;

$list-el-event: #42c7a8;
$endHourColor:  $color1;

$titleFontColor: white;

$lightGrey: #B0B0B0;

$linkColor: #000000;

$warningColor: #ffc107;

/* Two columns mode */
$leftColumnsWidth: 34%;
$rightColumnsWidth: 66%;

/* Call-to-action button (e.g main button on modals or "Display on map" button) */
$ctaBtnBackgroundColor: #48484a;
$ctaBtnPadding: 0.4em 0.7em 0.4em;

/* App toolbar */
$appToolbarHeight: 48px;
$appToolbarColor: $color1;
$appToolbarBackgroundColor: white;

/* Fiche */
$favIconSize: 38px;
$ficheIconsSize: 36px; // related data below description: places, events, etc
$ficheIconsColor: $color2;

/* Lang */
$currentLangBorderColor: #3eaac3;
/* in menu */
$menuLangIconSize: 2.4em;
$menuLangIconPosition: 20% center;
$menuItemHoverBackgroundColor: #ececec;
$menuItemIconsBackgroundColor: white;

/* Tile click effect */
$tileEffectScale: .96;
$tileEffectColor: white;
$tileEffectOpacity: .3;

$tileIconSize: 38px;
$tileTextColor: $fontColor;

/* Home toolbar */
$homeToolbarColor: $titleFontColor;
$homeToolbarBackgroundColor: $color1;
$homeToolbarBackgroundColorHover: $color1lighter;

$inboxCountBackgroundColor: white;
$inboxCountColor: $color1;

$homeBackgroundColor: #f6f6fa;

/* Banner component (!= AdBanner) */
$bannerBackgroundColor: white;
$bannerMaxHeight: 90px;

/* Share button */
$shareBtnColor: white;

/* List */
$favIconColor: #F5A400;
$listElBackgroundColorHover: #f2f2f2;
$listChevronColor: $color1lighter;
$listBorderColor: #d3d3d3;

@mixin emphasized() {
    color: #FF0614;
}

$indexHorizontalBarColor: white;

/* @see .horizontal-separator, used by ListElement, TypeBar */
$separatorBackgroundColor: $color1;
$separatorColor: white;


$listTextMinorStrongColor: #7b7b7b;
$listTextMinorStrongFontSize: 1em;

$listTextMinorColor: #565656;
$listTextMinorFontSize: .8em;

$listElCounterBackgroundColor: $color1evenMoreLighter;
$listElementImageSize: 50px;
$listElementImageWidth: 60px;
$listElementImageSmallWidth: 32px;

/* Alphabetical index */
$alphaIndexColor: white;
$alphaIndexBackgroundColor: $color1;


/* Side index */
$sideIndexColor: white;
$sideIndexGradient: linear-gradient(to bottom, $color1, $color2);
$sideIndexBackgroundColor: $color1;
$sideIndexSelectedBackgroundColor: $color2;
$sideIndexSelectedColor: white;


/* Search */
$searchFieldHeight: 3.5em;
$searchFieldBackgroundColor: white;
$searchFieldBorderColor: #a8a8a8;
$searchFieldClearButtonColor: white;
$searchFieldClearButtonBgColor: $color2;

/* Input */
$placeholderText: #656555;

/* TypeBar */
$typeBarIconColor: $color1lighter;
$typeBarColor: white;

/* Mobile-Spot Bar */
$mobileSpotBarColor: $titleFontColor;
$mobileSpotBarBackgroundColor: indigo;

/* Misc */
$chevronColor: $fontColor;


/* Filter dialog colors */
$fdLightest: #f8f8f8;
$fdLighter: #e3e3e3;
$fdLight: #eeeeee;
$fdMid: #a6a6a6;
$fdDarker: #000000;
$fdSelected: #000000;
$fdSelectedIcon: #ff7923;
$fdWarningColor: #ff7923;
$fdLinkColor : $color1;

/* Synoptic agenda */
$synoColumnWidth: 116px;
$synoBackgroundColor: white;
$synoPlacesRowHeight: 60px;
$synoBorderColor: lightgrey;
$synoHoursColumnWidth: 1.6em;
$synoEventTextColor: white;

/* image upload */
$imageUploadCameraBtnColor: lighten($color1lighter, 15%);
$imageUploadGalleryBtnColor: lighten($lightGrey, 15%);

/* image crop */
$imageCropCancelBtnColor: grey;
$imageCropConfirmBtnColor: #39b939;


/* KLIPSO LEADS */

$klTextColor: #646464;

$klThemeColor1: #000000;
$klThemeColor2: #000000;

$klHeaderColor: #FFFFFF;
$klHeaderBgColor: $klThemeColor2; // See also config/cordovaStatusBarConfig.js
$klHeaderHeight: 3em;
$klHeaderCloseBtnColor: #FFFFFF;

$klContentColor: $fontColor;
$klContentBgColor: $pageBackgroundColor;

$klStatusBlockBgColor: #efefef;
$klAllSyncColor: #00bf00;

$klCheckColor: #00bf00;
$klNotSyncColor: $klThemeColor1;

$klButtonsColor: white;
$klButtonsBgColor: $klThemeColor1;

$klFooterHeight: 3.5em;

$sortSearchBarHeight: 1.6em;

$klFieldBorder: 1px solid lightgrey;

$klOverlayLoaderBgColor: rgba(255, 255, 255, 0.7);
$klOverlayLoaderLabelBgColor: white;

// Klipso Leads questionnaire screen

$klFormMarginLeft: 50px;

$klFormContactRow1BgColor: #efefef;
$klFormCompanyIconColor: $klThemeColor1;
$klFormCompanyNameColor: $klThemeColor1;
$klFormNameIconColor: $klThemeColor2;

$klFormContactRow2BgColor: #f8f8f8;
$klFormContactRow2BorderBottom: 2px solid #efefef;
$klFormIconOtherColor: #aaaaaa;
$klMandatoryFieldNotSetColor: black;
$klFormTitleBgColor: #f8f8f8;
$klChevronColor: $klThemeColor2;
$klCheckBoxContainerBgColor: white;
$klCheckBoxColor: $klThemeColor2;

$klFormHistoryColor: #aaaaaa;

$klSearchFieldBottomBorder: 1px solid #dfdfdf;

$klEmsLuIconsColor: #aaaaaa;
$klEmsLuLabelsColor: #aaaaaa;
$klEmsLuValuesColor: $klTextColor;
$klEmsResetLicenceColor: #aaaaaa;
$klEmsHorizontalBorder: 2px solid #efefef;

$klEmsExportIconColor: $klThemeColor2;

$klLinkBorderColor:$linkColor;
$klLinkTextColor:$linkColor;

$klwarningColor: red;

// synoptic agenda color of tab selected 
$synopticAgendaTabSelected: red !important;
$vimeoBorderColor: transparent;


$BorderMenuColor: #747474;
$BorderMenuAppversion: #1d1d1f;

$DialogLangButColor:#647276;
$DialogLangColor: #E7E7E7;